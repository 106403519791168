.main-contact{
    .contact-title{
        background-image: url("../images/17.png");
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        h5{
            font-family: "Lato", sans-serif;
            font-size: 90px;
            font-weight: 700;
            line-height: 108px;
            color: rgba(255, 255, 255, 1);
        }
        h6{
            font-family: "Lato", sans-serif;
            font-size: 40px;
            font-weight: 600;
            line-height: 48px;
            color: rgba(255, 255, 255, 1);
        }
    }
    .location-email-call{
        margin: 60px 0px;
        .contact-boxs{
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
            width: 355px;
            height: 300px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            border-radius: 20px;
            
            h4{
                margin: 0;
                color:  rgba(20, 31, 57, 1);
                font-family: "Lato", sans-serif;
                font-size: 30px;
                font-weight: 700;
                line-height: 50px;
                letter-spacing: 0.05em;
                text-align: center;
            }  
            p{
                margin: 0;
                text-align: center;
                font-family: "Lato", sans-serif;
                color: rgba(145, 145, 145, 1);
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
            }  
            .img-box{
                background: rgb(241, 245, 255);
                border-radius: 88px;
                height: 156px;
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
            } 
        }
    }
    .get-touch-title{
        margin: 30px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 14px;

        h5{
            font-family: "Lato", sans-serif;
            font-size: 67px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: 0.05em;
            color: rgba(20, 31, 57, 1);
        }
        h6{
            font-family: "Lato", sans-serif;
            font-size: 35px;
            font-weight: 500;
            line-height: 60px;
            letter-spacing: 0.05em;
            color: rgba(20, 31, 57, 1);
        }
    }
}
.get-touch{
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 40px;
    border-radius: 40px;
    .submit-your-request{
        background-color: rgb(20, 31, 57);
        color: rgb(255, 255, 255);
        padding: 16px 24px;
        border: 0;
        border-radius: 36px;
        font-family: "Lato", sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }
}