*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
  --primary: 0, 152, 219;
}

.lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  h6{
    font-family: "Lato", sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 41.6px;
    letter-spacing: 1px;
    color: rgb(var(--primary));
    margin: 0;
}
h5{
     font-family: "Lato", sans-serif;
     font-size: 60px;
     font-weight: 700;
     line-height: 82px;
     letter-spacing: 0.05em;
     color: rgb(20, 31, 57);
     margin: 0;
     text-align: center;
}

// main{
  // margin-top: 96px;
// }

html {
  overflow-x: auto;
}

body{
  overflow-x: hidden;
}

.d-flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-50{
  padding-left: 50px;
  padding-right: 50px;
}

.gallery-img{
  padding: 20px 75px;
  .col-xxl-6{
    margin-top: 16px;
    margin-bottom: 16px;
  }
}