.home-container{
  .menuright{
    color: rgba(255, 255, 255, 1);
    font-size: 50px;
    display: none;
  }
  .scrolled{
      background:  rgba(0, 152, 219, 1);
      .logo-img{
        background-image: url('../images/05.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 56px;
        width: 13%;
      }
      .nav-list{
        .active{
          color: rgb(var(--primary));
          background-color: rgba(255, 255, 255, 1);
          padding: 10px 20px;
        }
        li{
          a {
            color: rgba(255, 255, 255, 1);
          }    
        }
      } 
      
      .btn-get-quote {
        background-color:  rgba(255, 255, 255, 1);
        color: rgb(0, 152, 219);
      }
  }
}
.main-navbar{
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 20px 75px;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     // background: linear-gradient(134.99deg, #F9D9D9 -1.8%, #F3F1FE 70.01%, #E2E0FB 144.39%);
     transition: background-color 0.3s ease;
     z-index: 1000;

  .logo-img{
        background-image: url('../images/01.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 56px;
        width: 13%;
        margin: 0;
  }
  .nav-list{
    list-style-type: none;
    display: flex;
    gap: 40px;
    margin: 0;
    .active{
      color: rgba(255, 255, 255, 1);
      background-color: rgba(255, 255, 255, 0.2);
      padding: 10px 20px;
    }
    li{
      font-family: "Lato", sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      color: rgb(62, 62, 62);
       a{
        color: rgba(255, 255, 255, 1);
         text-decoration: none;
       }
    }
  }
  .btn-get-quote{
    color: rgb(255, 255, 255);
    border: 0;
    padding: 10px 28px;
    background-color: rgb(var(--primary));
    border-radius: 36px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }
} 
.offcanvas-close{
  background-color: rgb(var(--primary), 0.1);
  height: 26px;
  width: 26px;
  border-radius: 12px;
}
.offcanvas-box{
  .nav-list{
    padding-left: 12px;
    gap: 12px;
    display: flex;
    flex-direction: column;

    li{
      list-style-type: none;
      padding: 12px 0px;
      border-bottom: 2px solid rgba(212, 212, 212, 1);
      .active{
        color: rgb(var(--primary));
        font-weight: 700;
      }
      a{
        text-decoration: none;
        color: rgba(66, 66, 66, 1);
        font-family: "Lato", sans-serif;
        font-size: 19px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        gap: 9px;
        .icons{
          font-size: 22px;  
        }
      }
    }
  }
}