@media only screen and (max-width: 1665px) {
    .our-partners{
        a{
            img{
                height: 235px;
            }
        }
    }
    .about-emi-locker {
        .about-emi{
            p {
                font-size: 14px;
            }
        }
    }
    .emi-locker {
        .locker-img {
            height: 500px;
        }
        h5 {
            font-size: 32px !important ;
            line-height: 70px !important;
        }
    }
    .working-experience {
        p {
            color: rgb(62, 62, 62);
            font-family: "Lato", sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 50px;
        }
    }
    .great-achievement {
        .achievement-renewed {
            .achievement{
                h5 {
                    font-size: 38px;
                }
                p{
                    font-size: 22px;
                }
            }
        }
        .great-title {
            font-size: 39px;
        }   
    }
    .main-navbar {
        .logo-img {
            height: 56px;
            width: 163px;
        }
    }
    .about-company {
        .company-img {
            height: 530px;
        }
    }
    .happy-clients {
        .ratings {
            h5 {
                font-size: 60px;
                line-height: 62px;
            }
            h6{
                font-size: 26px;
            }
        }
        .client {
            p {
                font-size: 22px;
                line-height: 37px;
            }
            h6{
                font-size: 42px;
                line-height: 47px;            
            }
        }
    }
}

@media only screen and (max-width: 1450px) {
    .our-partners {
        a {
            img {
                height: 216px;
                width: 100%;
            }
        }
    }
    .about-emi-locker{
        .about-emi {
            h5 {
                font-family: "Lato", sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 34px;
                letter-spacing: 0.05em;
            }
        }
    }
    .customer-busines{
        h5{
            font-size: 39px;
            line-height: 54px;
        }
        .busines-box{
            h5 {
                font-size: 22px;
            }
            p {
                font-size: 20px;
                line-height: 26.78px;
            }
        }
    }
    .features-locker {
        .features-box {
            p {
                font-size: 19px;
            }
        }
    }
    .we-are {
        .we-are-img {
            height: 523px;
        }
        .we-are-title{
            p {
                font-size: 16px;
                line-height: 37px;
            }
            h5 {
                font-size: 42px;
                line-height: 54px;
            }            
        }
    }
}

  @media only screen and (max-width: 1290px) {
    .our-partners {
        a{
            img {
                height: 12rem;
                width: 100%;
            }
        }
    }
    .customer-busines {
        padding: 20px 20px;
    }
    .features-locker {
        padding: 32px 20px;
        h5{
            font-size: 38px;
        }
        .features-img {
            height: 361px;
        }
        .features-box {
            p {
                font-size: 15px;
            }
        }
    }
    .locker-box {
        padding: 32px 20px;
    }
    .about-emi-locker {
        padding: 20px 20px;
        .about-emi {
            padding: 8px;
            margin: 15px 9px;
        }
        h5 {
            font-size: 42px;
        }
    }
    .we-are {
        padding: 50px 30px;
    }
    .emi-locker {
        .locker-img {
            height: 429px;
        }
        h5 {
            font-size: 28px !important;
            line-height: 53px !important;
        }
        .locker-title{
            h6{
                font-size: 20px;
            }
        }
    }

    .working-experience {
        h6 {
            font-size: 26px;
            line-height: 47px;
            text-align: end;
        
            p {
                font-size: 26px;
                line-height: 44px;
            }
        }
    }
    .happy-clients {
        .ratings {
            h1 {
                font-size: 80px;
            }
        }
    }
    .amazing-team {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .great-achievement {
        .achievement-renewed {
          .achievement-boxs{
            gap: 20px !important;   
          }
        }   
    }
    .help-build{
        .better-future {
            gap: 18px;
            h6 {
                font-size: 26px;
            }
            h5 {
                font-size: 36px;
                line-height: 50px;
            }
            p{
                font-size: 22px;
                margin: 0;
            }
            .learn-more {
                font-size: 22px;
                padding: 8px 27px;
            }
        }
    }
    .p-50 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .feedback-box{
        flex: 0 0 auto;
        width: 100%;
    }
    .embracing {
        flex-wrap: wrap;
        padding: 28px 15px;
    }
    .about-company{
        .col-5{
            flex: 0 0 auto;
            width: 50%;
        }
        .col-7{
            flex: 0 0 auto;
            width: 50%;
            h5 {
                font-size: 38px;
                line-height: 48px;
            }
            .title-About {
                p {
                    font-size: 16px;
                }
            }
            .customer-support {
                .workflow {
                    h4 {
                        font-size: 22px;
                        line-height: 50px;
                    }
                    p{
                        font-size: 16px;
                    }
                    .icon-play {
                        height: 38px;
                        width: 38px;
                    }
                    .icon-support {
                        height: 38px;
                        width: 38px;
                    }
                }
            }
        }
    }
    .happy-clients {
        padding: 28px;
        .ratings {
            h5 {
                font-size: 62px;
            }
            h6{
                font-size: 20px;
            }
        }
    }
  }

  @media only screen and (max-width: 1144px) {
    .our-partners {
        a{
            img {
                height: 10rem;
                width: 100%;
            }
        }
    }
    .main-navbar {
        .nav-list {
            li{
                font-size: 18px;
            }
        }
    }
    .customer-busines {
        .busines-box {
            h5 {
                font-size: 19px;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .locker-box {
        .box {
            p{
                font-size: 26px;
            }
        }
    }
    .about-emi-locker {
        .about-emi {
            margin: 15px 0px;
        }
    }
    .main-blogs .blogdetails-img img {
        height: 570px;
    }
    .great-achievement {
       margin-top: 20px;
        .achievement-renewed {
          .achievement-boxs{
           flex-wrap: wrap;   
          }
        }   
    }
    .help-build {
        padding-left: 22px;
        padding-right: 22px;
    }
    .trusted-by{
        display: flex;
        align-items: center;
        // flex-direction: column-reverse;
        .best-renewed{
            img{
                height: 100% !important;
            }
        }
    }
    .about-company{
        .company-img {
            height: 496px;
        }
    }
    .renewed-experts {
        .renewed-title {
            gap: 0px;
            h6 {
                font-size: 17px;
            }
            h5 {
                font-size: 36px;
                line-height: 73px;
            }
            p {
                font-size: 15px;
            }
        }
    }
  }

  @media only screen and (max-width: 1035px) {
    .customer-busines{
        .busines-box {
            margin: 0px;
        }
        .col-4{
            flex: 0 0 auto;
            width: 50%;
            margin-top: 14px;
            margin-bottom: 14px;
        }
    }
    .features-locker {
        .features-box{
            p {
                font-size: 13px;
            }
        }
    }
    .locker-box{
        .box {
            margin-left: 22px;
            margin-right: 22px;
        }
    }
    .about-emi-locker{
        .col-4{
            flex: 0 0 auto;
            width: 50%;
        }
    }
    .we-are{
        display: flex;
        flex-direction: column-reverse;

        .col-6 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .emi-locker {
         h5 {
            font-size: 22px !important;
            line-height: 35px !important;
        }
        .locker-title {
            h6 {
                font-size: 16px;
                line-height: 28px;
            }
            .try-now {
                font-size: 18px;
                padding: 4px 25px;
            }
        }
        .locker-img {
            height: 351px;
        }
    }
    .help-build {
        .better-future {
            p {
                font-size: 18px;
                margin: 0;
            }
        }
    }
    .vision-goal{
        flex-wrap: wrap;
        padding: 28px 15px;
        margin-top: 0px !important;
    }
    .happy-clients {
        .client {
            border-right: none;
            &:after {
                content: '';
                display: block;
                width: 200px;
                height: 3px;
                background: rgb(244, 244, 244, 0.1);
                margin: 0 auto;
                border-radius: 3px;
            }
        }
        .col-6{
            flex: 0 0 auto; 
            width: 100%;
            padding: 0 !important;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-bottom: 20px;
            margin-top: 10px;
        }
        .ratings {
            h5 {
                font-size: 47px;
                line-height: 25px;
            }
            h6 {
                font-size: 18px;
                text-align: center;
                line-height: 30px;
            }
            .star-fill {
                font-size: 28px;
            }
        }
        .client {
            gap: 0;
            h6 {
                font-size: 29px;
            }
            p {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
    .about-company {
        .company-img {
            height: 422px;
        }
        .customer-about {
            padding-top: 50px;
            padding-bottom: 50px;
            h5 {
                font-size: 30px;
                line-height: 40px;
            }
            .title-About{
                gap: 10px;
                h6 {
                    font-size: 20px;
                }
                p {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 700;
                }
            }
        }

    }
    .main-navbar {
        .nav-list li {
            font-size: 18px;
        }
    }
  }

  @media only screen and (max-width: 967px) {
    .features-locker{
        .justify-content-start{
            justify-content: center !important;
        }
        .col-5{
            flex: 0 0 auto;
            width: 100%;
        }
        .col-7{
            flex: 0 0 auto;
            width: 100%;        
        }
    }
    .locker-box {
        padding: 20px 20px;
    }
    .steps-solution{
        height: 404px;
    }
    .working-experience{
        h6 {
            font-size: 20px;
            line-height: 47px;
            text-align: end;
        }
        p {
            color: rgb(62, 62, 62);
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 34px;
        }
    }
    .help-build{
        display: flex;
        flex-direction: column-reverse;

        .col-6{
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .amazing-feedback{
        .feedback {
            display: none;
        }
    }
    .trusted-by {
        padding: 28px 25px;
        .col-6 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .home-container {
        .scrolled {
            .logo-img {
                width: 40%;
            }
        }
    }
    .about-company{
        .col-5{
            flex: 0 0 auto;
            width: 100%;
            margin-top: 60px;
        }
        .col-7{
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .main-navbar {
        .nav-list {
            display: none;
        }
        .btn-get-quote {
         display: none;
        }
    }
    .home-container{
        .menuright{
          display: block;
        }
    }
    .renewed-experts {
        padding: 0px 16px 30px;
    }
    .our-outlets-box{
        .box-wrap{
            flex-wrap: wrap;
        }
    }
  }

  @media only screen and (max-width: 855px) {
    .featured-in {
        .row {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    .main-navbar {
        padding: 20px 30px;
    }
    .emi-locker {
        .locker-img {
            height: 300px;
        }
        .locker-title {
            padding: 20px 28px;
        }
    }
    .main-blogs {
        .blogdetails-img {
            img {
                height: 429px;
            }
        }
    }
    h5 {
        font-size: 40px;
        line-height: 50px;
    }    
    h6 {
        font-size: 22px;
    }
    .trusted-by {
        padding: 28px 25px;
        flex-direction: column-reverse;
    }
  }

  @media only screen and (max-width: 892px) {
    .locker-box {
        .box {
            p {
                font-size: 24px;
            }
        }
    }
    .locker-box {
        .box {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
  }

  @media only screen and (max-width: 767px) {
    .our-partners a img {
        height: 100%;
        width: 100%;
    }

  }
  
  @media only screen and (max-width: 750px) {
    .locker-box{
        flex-wrap: wrap;
    }
    .locker-box {
        .box {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .about-emi-locker{
        h5 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        .col-4{
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .emi-locker {
        .locker-img {
            height: 265px;
        }
    }
    .working-experience{
        h6 {
            font-size: 18px;
            line-height: 33px;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 34px;
        }
    }
    .get-touch-title{
        margin-top: 20px;
    }
    .get-boxs{
        flex: 0 0 auto;
        width: 100%;
    }
    .trusted-by {
        padding: 28px 15px;
        .trusted-by-top {
            .trusted-title{
                p{
                    font-size: 15px;
                }
                h4 {
                    font-size: 20px;
                    margin: 0;
                }
            }
        }
    }

    .renewed-experts {
        .slider-img {  
            height: 231px;
            padding: 10px;
        }
    }
  }

  @media only screen and (max-width: 700px) {
    .emi-locker{
        display: flex;
        flex-direction: column-reverse;
        .col-6{
            flex: 0 0 auto;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    .working-experience {
        flex-direction: column;
    }
    .main-blogs {
        padding: 30px 25px;
    }
    .main-contact {
        .contact-title {
            h5 {
                font-size: 60px;
                line-height: 68px;
            }
            h6 {
                font-size: 34px;
                line-height: 37px;
            }
        }
    }
    .get-touch-box{
        margin-top: 28px;
    }

    .renewed-experts {
        .renewed-title {
            p {
                font-size: 11px;
            }
            h5 {
                font-size: 22px;
                line-height: 41px;
            }
            h6 {
                font-size: 14px;
                line-height: 23.6px;
            }
        }
    }
  }

  @media only screen and (max-width: 550px) {
    .faq-accordion {
        padding: 20px 18px;
    }
    .customer-busines{
      .col-4{
        flex: 0 0 auto;
    width: 100%;
      }
    }
    .features-locker{
    .col-6{
        flex: 0 0 auto;
        width: 100%;
    }
    }
    .we-are {
        padding-top: 20px;
        .we-are-img {
            height: 387px;
        }
    }
    .gallery-img {
        padding: 20px 20px;
    }
    .happy-clients {
        .ratings {  
           display: flex;
           flex-direction: column;
           gap: 10px;
           justify-content: center;
           align-items: center;
        }
    }
    .main-blogs {
        .blogdetails-img {
            img {
                height: 322px;
            }
        }
    }
    .help-build{
        .better-future {
            gap: 8px;
            h6 {
                font-size: 20px;
            }
            h5 {
                font-size: 29px;
                line-height: 36px;
            }
            p {
                font-size: 17px;
                margin: 0;
                line-height: 28px;
            }
            .learn-more {
                font-size: 20px;
                padding: 4px 25px;
            }
        }
    }
    h5 {
        font-size: 29px;
        line-height: 38px;
    }
    h6 {
        font-size: 18px;
    }
    .our-product {
        padding: 25px 0px;
    }
    .renewed-experts {
        padding: 0px 0px 30px 20px;
        .slider-img {
            height: 196px;
            padding: revert;
        }
    }

  }

  @media only screen and (max-width: 466px) {
    .our-newsletter h5 {
        font-size: 34px;
    }
    .about-emi-locker{
        .col-4{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .about-emi{
            height: 100%;
            margin: 0;
            img{
                height: 165px;
            }
        }
    }
    .customer-busines {
        h5 {
            font-size: 27px;
            line-height: 38px;
        }
    }
    .features-locker{
        .features-box {
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }
    
    .locker-box{
        flex-direction: column;
    }
    .happy-clients {
        .ratings {
            h1 {
                font-size: 53px;
                line-height: 42px;
            }
            h5 {
                font-size: 36px;
                line-height: 23px;
            }
            .star-fill {
                font-size: 25px;
            }
        }
    }
    .main-contact {
        .get-touch-title {
            h5 {
                font-size: 46px;
                line-height: 32px;
                margin-top: 14px;
            }
            h6 {
                font-size: 23px;
                line-height: 34px;
            }
        }
    }
    .great-achievement {
        .great-title {
            font-size: 26px;
        }
    }
    .latest-blogs {
        .user-information {
            .sub-title {
                font-size: 15px !important;
            }
            .title {
                font-size: 20px;
                line-height: 32px;
                height: 64px;
            }
        }
    }
    .about-company{
        .company-img {
            height: 382px;
        }
        .col-5 {
            margin-top: 22px;
        }
        .customer-about {
            padding-top: 15px;
            .title-About {
                h6 {
                    font-size: 18px;
                    line-height: 18px;
                }
                p {
                    font-size: 11px;
                }
            }
            .customer-support{
                .workflow{
                    p {
                        font-size: 14px;
                        margin: 0;
                    }
                    h4 {
                        font-size: 20px;
                        line-height: 32px;
                    }
                    .icon-play {
                        height: 30px;
                        width: 30px;
                        margin-top: 4px;
                    }
                    .icon-support {
                        height: 30px;
                        width: 30px;
                        margin-top: 4px;
                    }
                }
            }
            
        }
    }
    .main-navbar {
        padding: 20px 24px;
        .logo-img {   
            height: 56px;
            width: 212px;
        }
    }
    .renewed-experts {
        padding: 0px 0px 25px 0px;

        .renewed-title{
            h6 {
                font-size: 11px;
                line-height: 20.6px;
                margin-top: 20px;
            }
            h5 {
                font-size: 25px;
                line-height: 38px;
                margin-bottom: 10px;
            }   
            p {
                line-height: 24px;
                font-size: 12px;        
            }    
            .get-started{
                button:nth-child(odd) {
                    line-height: 22px;
                    font-size: 20px;
                }
                button:nth-child(even) {
                    line-height: 30px;
                    font-size: 18px;
                }
            } 
        }
    }
    .outlets-main-box {
        .outlets-body {
            h4 {
                font-size: 24px;
            }
            p{
                font-size: 16px;
            }
        }
    }
    .outlets-two {
        .outlets-body{
            .title-two {
                font-size: 16px;
            }
        }
    }
  }

  @media only screen and (max-width: 440px) {
    .our-outlates-gallery {
        .gallery {
            img {
                width: 350px;
                height: 350px;
            }
        }
    }
    .we-are {
        padding: 10px;
    }
    .steps-solution {
        height: 435px;
    }
    .main-footer {
        padding: 30px 22px;
        .content-logo {
            p {
                text-align: center;
            }
        }
    }
    .company-resources{
        ul{
            text-align: center;
            padding: 0;
        }
        h6 {
            text-align: center;
        }
    }
    .main-blogs {
        padding: 4px 12px;
    }
    .happy-clients {
        padding: 6px;
        padding-top: 14px;
        padding-bottom: 14px;
        .ratings-box{
            gap: 16px !important;
        }
    }
    .renewed-experts{
        .slider-img {
            height: 165px;
        }
    } 
  }

  @media only screen and (max-width: 400px) {
    .our-outlates-gallery {
        .gallery {
            img {
                width: 330px;
                height: 300px;
            }
        }
    }
    .features-locker {
        .features-img {
            height: 323px;
        }
    }
    .about-emi-locker {
        padding: 20px 12px;
        .about-emi{
            h5 {
                font-size: 32px;
                line-height: 58px;
                margin: 0;
            }
        }
        .about-emi{
            img{
                height: 167px;
            }
        }
    }
    .we-are {
        padding: 10px 10px;
        .we-are-title {
            h5 {
                font-size: 30px;
                line-height: 54px;
            }
            p {
                font-size: 15px;
                line-height: 27px;
            }
        }
        .we-are-img {
            height: 324px;
        }
    }
    .main-blogs {
        .blogdetails-img {
            img {
                height: 257px;
            }
        }
    }
    .amazing-team {
        height: 322px;
        width: 322px;
        img{
            height: 205px;
        }
    }
    .renewed-smartphone {
        flex-direction: column;
        align-items: center;    
    }

    .happy-clients {    
        .client {
            h6 {
                font-size: 24px;
            }
            p {
                font-size: 13px;
                line-height: 26px;
            }   
        }
        .ratings {
            h5 {
                font-size: 40px;
            }
            h6 {
                font-size: 15px;
            }
            .star-fill {
                font-size: 24px;
            }
        }
    }
    .home-container {
        .scrolled {
            .logo-img {
                width: 66%;
            }
        }
    }
    .about-company {
        .customer-about {
            .customer-support {
                .workflow {
                    p {
                        font-size: 13px;
                        margin: 0;
                    }
                }
            }
        }
    }
    .renewed-experts{
        .renewed-title {
            h5 {
                font-size: 18px;
                line-height: 35px;
            }
            p {
                font-size: 10px;
                font-weight: 800;
            }
        }
        .slider-img {
            height: 152px;
        }
    } 
    .outlets-main-box{
        .outlets-footer{
          div{
            flex-wrap: wrap;
            gap: 12px;
          }
        }
    }
    .outlets-two{
        .outlets-footer{
           div{
            flex-wrap: wrap;
            gap: 12px;
           }
        }
    }
    
  }

  @media only screen and (max-width: 360px) {
    .our-outlates-gallery {
        .gallery {
            img {
                width: 300px;
                height: 250px;
            }
        }
    }
    
    .we-are {
        .we-are-img {
            height: 310px;
        }
        .we-are-title {
            h5 {
                font-size: 26px;
                line-height: 38px;
            }
            p {
                font-size: 12px;
                line-height: 23px;
            }
        }
    }
    .steps-solution {
        .steps-solution-icons {
            height: 106px;
            padding: 7px;
        }
        .expert-text {
            p {
              margin: 0;       
            }
        }
    }
    .happy-clients {
        .ratings {
            h6 {
                font-size: 13px;
            }
        }
    }
    .renewed-experts {
        .renewed-title {
            .get-started {
                flex-direction: column;
                button:nth-child(odd) {
                    line-height: 22px;
                    font-size: 20px;
                }
                button:nth-child(even) {
                    line-height: 22px;
                    font-size: 20px;
                    padding: 10px;
                }
            }
        }
    }
  }
