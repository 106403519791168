 .main-title{
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 40px;
    }
.opening-current{
    padding-left: 100px;
    padding-right: 100px;
    .job-opening{
        background-color: rgb(152, 152, 152, 0.1);
        border: 2px solid rgb(152, 152, 152, 0.3);
        border-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 22px 12px;
        gap: 14px;
        margin-bottom: 50px;
        margin-left: 20px;
        margin-right: 20px;
        height: 288px;  
        h5{
            font-family: "Lato", sans-serif;
            font-size: 41px;
            font-weight: 800;
            line-height: 61px;
            letter-spacing: 0.1em;
            color: rgb(0, 152, 219);
            background-size: 200% auto;
            animation: textclip 8s linear infinite;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            text-align: center;
        }
        h6{
            font-family: "Lato", sans-serif;
            font-size: 26px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: 0.1em;
            color: black;
            margin: 0;
            text-align: center;
        }
        p{
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0.1em;    
            color: var(--text-color);      
            margin: 0;
        }
        a {
            border-radius: 7px;
            background-color: rgb(0, 152, 219);
            color: rgb(255, 255, 255);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
            padding: 10px 52px; 
            border: 0;
            color: var(--white);
            font-weight: 600;
            font-size: calc(10px + 9 * (100vw - 300px) / 1300);
            margin-top: 20px;
            text-decoration:none
        }
    }
}