.help-build{
    padding-left: 70px;
    padding-right: 70px;

    .better-future{
        display: flex;
        flex-direction: column;
        gap: 26px;
        
        .learn-more{
            color: rgb(255, 255, 255);
            background-color: rgb(0, 152, 219);
            font-family: "Lato", sans-serif;
            font-size: 28px;
            font-weight: 600;
            line-height: 35px;
            padding: 18px 42px;
            border-radius: 47px;
            border: 0;
        }
        h6 {
            font-size: 33px;
        }
        h5 {
            font-size: 65px;
        }
        p{
            font-family: "Lato", sans-serif;
            font-size: 26px;
            font-weight: 400;
            line-height: 43px;
            color: rgb(62, 62, 62);
            margin-bottom: 35px;
        }
    }
}
.great-achievement{
    background-color: rgb(var(--primary));
    
    padding: 20px;
    .great-title{
        text-align: center;
        font-size: 45px;
        color: rgb(255, 255, 255);
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .achievement-renewed{
        .achievement{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 20px;

            .icons{
                background-color: rgb(244, 248, 255);
                padding: 18px;
                color: rgb(21,32,58);
                height: 100px;
                width: 140px;
                border-radius: 62px;
            }
           h5{
             color:  rgba(244, 248, 255, 1);
             font-family: "Lato", sans-serif;
             font-size: 45px;
             font-weight: 700;
             line-height: 28px;
             letter-spacing: 0.05em;
             margin-top: 32px;
           }
           p{
             color:  rgba(244, 248, 255, 1);
             font-family: "Lato", sans-serif;
             font-size: 25px;
             font-weight: 500;
             line-height: 60px;
             letter-spacing: 0.05em;
             margin: 0;
           } 
        }
    }
}
.amazing-team{
    background-color: rgba(242, 245, 255, 1);
    padding: 20px;
    height: 322px;
    width: 378px;
    border-radius: 62px;
    position: relative;
    
    h5 {
        font-size: 32px;
        line-height: 69px;
        display: flex;
        justify-content: center;
    }
    p{
        color:  rgba(131, 131, 131, 1);
        font-family: "Lato", sans-serif;
        font-size: 22px;
        font-weight: 400;
        line-height: 26.4px;
        text-align: center;
    }
    img{
        height: 254px;    
        position: absolute;
        left: 40px;
    }
}
.director{
    font-family: "Lato", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 40px;
    color: rgb(var(--primary));
    margin-bottom: 0px;
}
.working-experience{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 20px;

    p{
        color: rgb(62, 62, 62);
        font-family: "Lato", sans-serif;
        font-size: 28px;
        font-weight: 400;
        line-height: 50px;
    }
    h6{
        color: rgba(0, 0, 0, 1);
        font-family: "Lato", sans-serif;
        font-size: 42px;
        font-weight: 700;
        line-height: 70px;
        p{
            color: rgb(var(--primary));
            font-family: "Lato", sans-serif;
            font-size: 42px;
            font-weight: 700;
            line-height: 70px;
        }
    }
}
.quality-expert{
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 250px;
    border-radius: 6px;

    .quality-icon{
        font-size: 100px;
        color: rgb(var(--primary));
    }
    .expert-text{
        h6{
            font-family: "Lato", sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 50px;
            text-align: center;
            color: rgba(66, 66, 66, 1); 
        }
        p{
            color: rgb(var(--primary));
            font-family: "Lato", sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 50px;
            text-align: center;
        }
    }
}
.steps-solution{
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    background-color:  rgba(252, 252, 252, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 390px;
    border-radius: 6px;
    padding: 12px;

    .steps-solution-icons{
        height: 144px;
        padding: 18px;
        border-radius: 18px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: rgba(246, 246, 246, 1);   
    }

    .quality-icon{
        font-size: 100px;
        color: rgb(var(--primary));
    }
    .expert-text{
        h6{
            font-family: "Lato", sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 50px;
            text-align: center;
            color: rgb(var(--primary));
        }
        p{
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;   
            color: rgba(62, 62, 62, 1);
        }
    }
}