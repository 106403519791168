.outlets-main-box {
    padding: 30px 0px;
    background-color: white;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    img {
        width: 100%;
        padding: 0px 30px;
    }
    .outlets-body {
        padding: 30px 0px;
        border-bottom: 1px solid rgb(207, 207, 207, 0.2);
        h4 {
            color: rgba(20, 31, 57, 1);
            font-size: 30px;
            line-height: 30px;
            font-weight: 500;
            margin-bottom: 15px;
            padding-left: 30px;
        }
        p { 
            color: rgba(166, 166, 166, 1);
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 0px;
            padding-left: 30px;
        }
    }
    .outlets-footer {
        padding: 30px 30px 0px 30px;
        .call-information {
            a {
                text-decoration: none;
                color: rgba(20, 31, 57, 1);
                font-size: 20px;
                font-weight: 500;
                display: flex;
                align-items: center;

                .phone-icon {
                    background-color: rgb(0, 152, 219, 0.1);
                    color: rgb(0, 152, 219);
                    padding: 10px;
                    font-size: 47px;
                    border-radius: 10px;
                    margin-right: 10px;
                }
            }
        }
        .loaction-information {
            a {
                text-decoration: none;
                color: rgba(20, 31, 57, 1);
                font-size: 20px;
                font-weight: 500;
                display: flex;
                align-items: center;

                .map-icon {
                    background-color: rgb(251, 81, 81,0.1);
                    color: rgb(251, 81, 81);
                    padding: 10px;
                    font-size: 47px;
                    border-radius: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    
}
.our-outlates-gallery{
    .tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        gap: 20px;
        .tab {
            padding: 10px 36px;
            border-radius: 5px;
            cursor: pointer;
            background-color: rgb(0, 152, 219, 0.1);
            color: rgb(0, 152, 219);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1px;
        }
        .active {
            background-color: rgb(0, 152, 219);
            color: white;
        }
    }
    
    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        img {
            width: 500px;
            height: 350px;
            object-fit: fill;
            margin: 10px;
            border-radius: 5px;
        }
        video {
            border-radius: 5px;
            margin: 10px;
        }
    }
} 
.outlets-two{
    .outlets-two-img{
        width: 93%;
    }
    .outlets-body{
        .title-two{
            font-size: 19px;
        }
    }
}

.office-tour-customers {
    h6 {
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        width: 160px;
        text-align: center;
        margin: auto;
        white-space: nowrap;
        padding-bottom: 13px;
        &:before {
            background-color: rgba(166, 166, 166, 1);
            content: '';
            display: block;
            height: 3px;
            width: 75px;
            margin-bottom: 5px;
        }
    }
}