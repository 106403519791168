.main-footer{
    background-color: rgba(19, 19, 19, 1);
    padding: 30px 50px;
    margin-top: 20px;
    .content-logo{
        p{
            color: rgb(255, 255, 255);
            font-family: "Lato", sans-serif;
            font-size: 22px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0.05em;
            margin-top: 16px;
        }
        .social-icons {
            div {
                display: flex;
                gap: 24px;
                align-items: center;
                margin-top: 48px;

                svg {
                    color: white;
                    font-size: 42px;
                    transition: all 0.3s;
                    &:hover {
                        color: #C3C3C3;
                    }
                }
            }
        }
        
    }
    .company-resources{
        display: flex;
        gap: 10px;
        flex-direction: column;
        
        h6{
            color: rgba(255, 255, 255, 1);
            border-bottom: 1px solid rgb(255, 255, 255,0.5);
            font-family: "Lato", sans-serif;
            font-size: 36px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: 0.05em;
            padding-bottom: 10px;
        }
        ul{
            li{
                list-style-type: none;
               a{
                list-style-type: none;
                color: rgba(255, 255, 255, 0.9);
                font-family: "Lato", sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 54px;
                letter-spacing: 0.05em;
                text-decoration: none;
               }
            }
        }
    }
    .product-by{
        .contact{
            h6{
                color: rgb(0, 152, 219);
                font-size: 20px;
                font-weight: 500;
                line-height: 40px;
                text-align: start;
            }
            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                color: #C3C3C3;
                margin: 0px;
            }
        }
    }
}