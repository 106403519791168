.emi-locker{
    background: linear-gradient(216.85deg, #57ABFF 19.95%, #96B8F9 47.75%, #EFF6FF 87.33%);

    padding-top: 78px;
    .locker-title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 75px;
        h5{
            text-align: start;
            line-height: 93px;
        }
        .try-now{
            background-color: rgb(var(--primary));
            color: rgb(255, 255, 255);
            font-family: "Lato", sans-serif;
            font-size: 23px;
            font-weight: 600;
            line-height: 32.72px;
            border: 0;
            padding: 10px 30px;
            border-radius: 6px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        }
        .play-video{
            color: rgb(var(--primary));
            background-color: rgb(255, 255, 255);
            box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
            height: 40px;    
            width: 40px;
            border-radius: 50%;
            padding: 5px;
            padding-left: 8px;    
            cursor: pointer;    
        }
    }
    .locker-img{
        height: 636px;
    }
}
.we-are{
    padding: 50px 75px;
    .we-are-title{
        h5{
            text-align: start;
        }
        p{
            color: rgba(62, 62, 62, 1);
            font-family: "Lato", sans-serif;
            font-size: 23px;
            font-weight: 400;
            line-height: 45px;
        }
        .read-more{
            background-color: rgb(var(--primary));
            color: rgb(255, 255, 255);
            font-family: "Lato", sans-serif;
            font-size: 23px;
            font-weight: 600;
            line-height: 41.72px;
            padding: 4px 20px;
            border-radius: 4px;
            border: 0;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        }
    }
    .we-are-img{
        height: 630px;
    }
}
.about-emi-locker{
    padding: 20px 75px;
    margin-top: 20px;

    .about-emi{
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 30px 20px;
        border-radius: 40px;
        height: 428px;

        h5{
            font-family: "Lato", sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 44px;
            letter-spacing: 0.05em;
        }

        p{
            text-align: center;
            color: rgb(132, 132, 132);
            font-family: "Lato", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.05em;
        }

    }
   
}
.locker-box{
    background-color: rgb(var(--primary));
    padding: 32px 75px;
    display: flex;
    justify-content: center;

    .box{
        margin-left: 40px;
        margin-right: 40px;
        .icon{
            font-size: 72px;
            background-color: rgba(244, 248, 255, 1);
            width: 100px;
            padding: 14px 12px;
            border-radius: 48px;
            color: rgb(var(--primary));
            margin-bottom: 30px;
        }
        text-align: center;
       h6{
        color: rgba(255, 255, 255, 1);
        font-family: "Lato", sans-serif;
        font-size: 45px;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 25px;
       }
       p{
        color:  rgba(255, 255, 255, 1);
        font-family: Lato;
        font-size: 30px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0.05em;
        margin: 0;
       }
    }
}
.features-locker{
    padding: 32px 75px;
    .features-img{
        height: 480px;
    }
    .features-box{
        display: flex;
        gap: 16px;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    
        .check-icon{
            background-color: rgb(var(--primary));
            color: rgb(244, 248, 255);
            border-radius: 50%;
            padding: 6px;
            font-size: 36px;
        }
        p{
            margin: 0;
            color: rgba(20, 31, 57, 1);
            font-family: "Lato", sans-serif;
            font-size: 22px;
            font-weight: 600;
            line-height: 47.42px;
        }
    }
}
.customer-busines{
    padding: 20px 75px;
    .busines-box{
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-top: 30px;
        height: 100%;

        h5{
            font-family: "Lato", sans-serif;
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0.05em;
            text-align: center;
            margin-top: 10px;
        }
        p{
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 43.78px;
            text-align: center;
            color: rgba(151, 151, 151, 1);
            margin: 0;
        }
        .busines-icon{
            color: rgb(255, 255, 255);
            background-color: rgb(2, 143, 232);
            font-size: 73px;
            padding: 7px;
            width: 127px;
            border-radius: 46px;
        }
    }
}
.faq-accordion{
    padding: 20px 75px;
    .accordion-button {
        background-color: rgba(249, 249, 249, 1);
    }
    .accordion-body{
        border: 1px solid rgb(0 0 0 / 12%);
    }
}
.our-newsletter{
    background-color: rgb(var(--primary));
    padding: 36px 12px;
    margin-top: 30px;
    margin-bottom: 30px;

    h5{
        color: rgb(255, 255, 255);
        font-family: "Lato", sans-serif;
        font-size: 50px;
        font-weight: 700;
        line-height: 57.2px;
        margin-bottom: 20px;
    }
    .input-btn{
      display: flex;
      justify-content: center;
      input{
        background-color: rgb(248, 248, 248);
        width: 532px;
        height: 50px;
        border: 0;
        padding: 8px 20px;
      }
      button{
        border: 0;
        padding: 13px 16px;
        background-color: rgba(19, 19, 19, 1);
        color: rgba(222, 222, 222, 1);    
      }
    }
}