:root {
    --primary: #843DF3;
    --secondary: #A343F1;
    --success: #1F65B6;
    --card: #120923;
    --dark: #0E0E0E;
    --white: #fff;
    --grey: 152, 152, 152;
    --text-color: #BCBCBC;
    --font-family: Londrina Solid;
    --body: #0B0518;
    --section-box: #989898;
    --animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --animation-duration: 1s;
  }