:root {
  --primary: #843DF3;
  --secondary: #A343F1;
  --success: #1F65B6;
  --card: #120923;
  --dark: #0E0E0E;
  --white: #fff;
  --grey: 152, 152, 152;
  --text-color: #BCBCBC;
  --font-family: Londrina Solid;
  --body: #0B0518;
  --section-box: #989898;
  --animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-duration: 1s;
}

.home-container .menuright {
  color: rgb(255, 255, 255);
  font-size: 50px;
  display: none;
}
.home-container .scrolled {
  background: rgb(0, 152, 219);
}
.home-container .scrolled .logo-img {
  background-image: url("../images/05.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 56px;
  width: 13%;
}
.home-container .scrolled .nav-list .active {
  color: rgb(var(--primary));
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
}
.home-container .scrolled .nav-list li a {
  color: rgb(255, 255, 255);
}
.home-container .scrolled .btn-get-quote {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 152, 219);
}

.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 75px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color 0.3s ease;
  z-index: 1000;
}
.main-navbar .logo-img {
  background-image: url("../images/01.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 56px;
  width: 13%;
  margin: 0;
}
.main-navbar .nav-list {
  list-style-type: none;
  display: flex;
  gap: 40px;
  margin: 0;
}
.main-navbar .nav-list .active {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
}
.main-navbar .nav-list li {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: rgb(62, 62, 62);
}
.main-navbar .nav-list li a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.main-navbar .btn-get-quote {
  color: rgb(255, 255, 255);
  border: 0;
  padding: 10px 28px;
  background-color: rgb(var(--primary));
  border-radius: 36px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.offcanvas-close {
  background-color: rgb(var(--primary), 0.1);
  height: 26px;
  width: 26px;
  border-radius: 12px;
}

.offcanvas-box .nav-list {
  padding-left: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.offcanvas-box .nav-list li {
  list-style-type: none;
  padding: 12px 0px;
  border-bottom: 2px solid rgb(212, 212, 212);
}
.offcanvas-box .nav-list li .active {
  color: rgb(var(--primary));
  font-weight: 700;
}
.offcanvas-box .nav-list li a {
  text-decoration: none;
  color: rgb(66, 66, 66);
  font-family: "Lato", sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  gap: 9px;
}
.offcanvas-box .nav-list li a .icons {
  font-size: 22px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: 0, 152, 219;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

h6 {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: 1px;
  color: rgb(var(--primary));
  margin: 0;
}

h5 {
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 82px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
  margin: 0;
  text-align: center;
}

html {
  overflow-x: auto;
}

body {
  overflow-x: hidden;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.gallery-img {
  padding: 20px 75px;
}
.gallery-img .col-xxl-6 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.feedback-img {
  background-image: url("../images/04.png");
  background-repeat: no-repeat;
  height: 347px;
  width: 100%;
}

.renewed-experts {
  background-image: url("../images/02.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.renewed-experts .renewed-title {
  display: flex;
  flex-direction: column;
  gap: 35px;
  text-align: center;
}
.renewed-experts .renewed-title .get-started {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}
.renewed-experts .renewed-title .get-started button:nth-child(odd) {
  background-color: rgb(0, 152, 219);
  border: 0;
  padding: 12px 26px;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  border-radius: 43px;
}
.renewed-experts .renewed-title .get-started button:nth-child(even) {
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
  padding: 12px 26px;
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 43px;
}
.renewed-experts .renewed-title p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  margin: 0;
}
.renewed-experts .renewed-title h5 {
  font-family: "Lato", sans-serif;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  letter-spacing: 0.05em;
  text-align: center;
  color: rgb(255, 255, 255);
}
.renewed-experts .slider-img {
  height: 100%;
  padding: 30px;
}

.amazing-feedback .bg_img {
  position: absolute;
}

.about-company .company-img {
  height: 620px;
}
.about-company .customer-about {
  padding-top: 75px;
  padding-bottom: 75px;
}
.about-company .customer-about .title-About {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about-company .customer-about .title-About p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: rgb(62, 62, 62);
  letter-spacing: 1px;
  margin: 0;
}
.about-company .customer-about .customer-support {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.about-company .customer-about .customer-support .workflow {
  display: flex;
  gap: 10px;
}
.about-company .customer-about .customer-support .workflow h4 {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
  margin: 0;
}
.about-company .customer-about .customer-support .workflow p {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: rgb(145, 145, 145);
}
.about-company .customer-about .customer-support .workflow .icon-play {
  color: rgb(255, 255, 255);
  background-color: rgb(var(--primary));
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  padding: 0px;
  padding-left: 6px;
  margin-top: 6px;
}
.about-company .customer-about .customer-support .workflow .icon-support {
  color: rgb(255, 255, 255);
  background-color: rgb(var(--primary));
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  padding: 6px;
  margin-top: 6px;
}

.happy-clients {
  background-color: rgb(0, 152, 219);
  padding: 68px;
}
.happy-clients .client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-right: 2px solid rgba(244, 244, 244, 0.5);
}
.happy-clients .client h6 {
  font-family: "Lato", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.05em;
  color: rgb(255, 255, 255);
}
.happy-clients .client p {
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 48px;
  color: rgb(255, 255, 255);
}
.happy-clients .ratings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
.happy-clients .ratings h1 {
  font-family: "Lato", sans-serif;
  font-size: 100px;
  font-weight: 700;
  line-height: 62px;
  color: rgb(255, 255, 255);
}
.happy-clients .ratings .star-fill {
  color: rgb(255, 193, 7);
  font-size: 34px;
}
.happy-clients .ratings h5 {
  font-family: "Lato", sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 62px;
  color: rgb(255, 255, 255);
}
.happy-clients .ratings h6 {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: rgb(255, 255, 255);
}

.view-all {
  background-color: rgb(var(--primary));
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 52px;
  padding: 12px 42px;
  border: 0px;
  border-radius: 50px;
  margin-right: 60px;
}

.our-product {
  padding: 50px 70px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trusted-by {
  padding: 28px 70px;
}
.trusted-by .trusted-next-icon {
  background-color: rgb(var(--primary));
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  position: absolute;
  right: 24px;
  bottom: 129px;
  z-index: 111;
  cursor: pointer;
}
.trusted-by .trusted-by-top {
  width: 100%;
  height: 370px;
  border-radius: 30px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  position: relative;
}
.trusted-by .trusted-by-top .trusted-title {
  height: 40%;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.trusted-by .trusted-by-top .trusted-title p {
  color: rgb(145, 145, 145);
}
.trusted-by .trusted-by-top .trusted-title h4 {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0.05em;
}
.trusted-by .trusted-by-top .trusted-img {
  height: 60%;
}
.trusted-by .trusted-by-top .trusted-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 26px 26px 0px 0px;
}

.our-services {
  height: 390px !important;
}

.provide-facility {
  display: flex;
  align-items: center;
  gap: 20px;
}
.provide-facility p {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
}

.employee {
  height: 80px;
  width: 100px;
  background: rgb(var(--primary), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 18px;
  color: rgb(20, 31, 57);
}

.achieve-care {
  background-color: rgb(20, 31, 57);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  position: absolute;
  right: 24px;
  bottom: 145px;
  z-index: 111;
  cursor: pointer;
  border: 2px solid rgb(255, 255, 255);
  padding: 11px;
}

.renewed-smartphone {
  display: flex;
  gap: 30px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  background: rgb(255, 255, 255);
  padding: 24px;
  border-radius: 20px;
}
.renewed-smartphone .better-solutions h4 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1px;
  color: rgb(20, 31, 57);
  margin: 0;
}
.renewed-smartphone .better-solutions p {
  color: rgb(124, 124, 124);
  margin: 0;
}

.latest-blogs {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  background: rgb(255, 255, 255);
  padding: 20px 24px;
  border-radius: 24px;
  max-width: 100%;
}
.latest-blogs .blogs-subtitle {
  border-top: 1px solid rgb(227, 227, 227);
  padding-top: 10px;
  color: rgb(124, 124, 124);
}
.latest-blogs .user-profile {
  display: flex;
  justify-content: space-between;
}
.latest-blogs .user-profile .user-img {
  height: 26px;
}
.latest-blogs .user-profile .right-arrow {
  width: 22px;
}
.latest-blogs .user-profile .read-more {
  cursor: pointer;
  text-decoration: none;
  color: rgb(57, 57, 57);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-align: left;
}
.latest-blogs .user-information {
  border-bottom: 2px solid rgba(227, 227, 227, 0.5);
}
.latest-blogs .user-information .dec {
  background: rgb(var(--primary));
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  height: 36px;
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.latest-blogs .user-information .title {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
  overflow: hidden;
  max-width: 100%;
  height: 75px;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.latest-blogs .user-information .sub-title {
  color: rgb(124, 124, 124);
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.05em;
  overflow: hidden;
  max-width: 100%;
  height: 86px;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.latest-slick .slick-arrow {
  display: none !important;
}
.latest-slick .box-blog {
  padding: 24px 14px;
  position: relative;
}

.facility-employee {
  padding: 28px 150px;
}

.beyond-boundarie {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  border-radius: 30px;
}
.beyond-boundarie img {
  height: 110px;
}
.beyond-boundarie p {
  text-align: center;
  color: rgb(132, 132, 132);
}

.embracing {
  padding: 28px 70px;
}
.embracing .our-core-values {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  border-radius: 30px;
}
.embracing .our-core-values img {
  height: 110px;
}
.embracing .our-core-values p {
  text-align: center;
  color: rgb(132, 132, 132);
}

.amazing-feedback .user_img {
  height: 47px;
}
.amazing-feedback .feedback {
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--primary));
  width: 170px;
  height: 74px;
  padding: 10px 20px;
  gap: 4px;
  justify-content: center;
  position: absolute;
  right: 14px;
  border-radius: 30px 0px 0px 30px;
}
.amazing-feedback .feedback h4 {
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: rgb(255, 255, 255);
  margin: 0;
}
.amazing-feedback .feedback .star {
  color: rgb(253, 253, 253);
}
.amazing-feedback .feedback-title h4 {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
  margin: 0;
}
.amazing-feedback .feedback-title p {
  color: rgb(164, 164, 164);
  font-family: "Lato", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
  margin: 0;
}

.featured-in .row {
  padding-left: 75px;
  padding-right: 75px;
}
.featured-in .featured-box {
  background-color: rgba(0, 152, 219, 0.32);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
.featured-in .featured-box img {
  border-radius: 10px;
}
.featured-in .featured-box a {
  padding: 0px 9px;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
  text-decoration: none;
  margin-top: 6px;
  cursor: pointer;
}

.main-contact .contact-title {
  background-image: url("../images/17.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-contact .contact-title h5 {
  font-family: "Lato", sans-serif;
  font-size: 90px;
  font-weight: 700;
  line-height: 108px;
  color: rgb(255, 255, 255);
}
.main-contact .contact-title h6 {
  font-family: "Lato", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  color: rgb(255, 255, 255);
}
.main-contact .location-email-call {
  margin: 60px 0px;
}
.main-contact .location-email-call .contact-boxs {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  width: 355px;
  height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
}
.main-contact .location-email-call .contact-boxs h4 {
  margin: 0;
  color: rgb(20, 31, 57);
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.05em;
  text-align: center;
}
.main-contact .location-email-call .contact-boxs p {
  margin: 0;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: rgb(145, 145, 145);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.main-contact .location-email-call .contact-boxs .img-box {
  background: rgb(241, 245, 255);
  border-radius: 88px;
  height: 156px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-contact .get-touch-title {
  margin: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
}
.main-contact .get-touch-title h5 {
  font-family: "Lato", sans-serif;
  font-size: 67px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
}
.main-contact .get-touch-title h6 {
  font-family: "Lato", sans-serif;
  font-size: 35px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.05em;
  color: rgb(20, 31, 57);
}

.get-touch {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 40px;
}
.get-touch .submit-your-request {
  background-color: rgb(20, 31, 57);
  color: rgb(255, 255, 255);
  padding: 16px 24px;
  border: 0;
  border-radius: 36px;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.help-build {
  padding-left: 70px;
  padding-right: 70px;
}
.help-build .better-future {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.help-build .better-future .learn-more {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 152, 219);
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  padding: 18px 42px;
  border-radius: 47px;
  border: 0;
}
.help-build .better-future h6 {
  font-size: 33px;
}
.help-build .better-future h5 {
  font-size: 65px;
}
.help-build .better-future p {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 43px;
  color: rgb(62, 62, 62);
  margin-bottom: 35px;
}

.great-achievement {
  background-color: rgb(var(--primary));
  padding: 20px;
}
.great-achievement .great-title {
  text-align: center;
  font-size: 45px;
  color: rgb(255, 255, 255);
  margin-top: 16px;
  margin-bottom: 16px;
}
.great-achievement .achievement-renewed .achievement {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.great-achievement .achievement-renewed .achievement .icons {
  background-color: rgb(244, 248, 255);
  padding: 18px;
  color: rgb(21, 32, 58);
  height: 100px;
  width: 140px;
  border-radius: 62px;
}
.great-achievement .achievement-renewed .achievement h5 {
  color: rgb(244, 248, 255);
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.05em;
  margin-top: 32px;
}
.great-achievement .achievement-renewed .achievement p {
  color: rgb(244, 248, 255);
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.05em;
  margin: 0;
}

.amazing-team {
  background-color: rgb(242, 245, 255);
  padding: 20px;
  height: 322px;
  width: 378px;
  border-radius: 62px;
  position: relative;
}
.amazing-team h5 {
  font-size: 32px;
  line-height: 69px;
  display: flex;
  justify-content: center;
}
.amazing-team p {
  color: rgb(131, 131, 131);
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: center;
}
.amazing-team img {
  height: 254px;
  position: absolute;
  left: 40px;
}

.director {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 40px;
  color: rgb(var(--primary));
  margin-bottom: 0px;
}

.working-experience {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 20px;
}
.working-experience p {
  color: rgb(62, 62, 62);
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 50px;
}
.working-experience h6 {
  color: rgb(0, 0, 0);
  font-family: "Lato", sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 70px;
}
.working-experience h6 p {
  color: rgb(var(--primary));
  font-family: "Lato", sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 70px;
}

.quality-expert {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  border-radius: 6px;
}
.quality-expert .quality-icon {
  font-size: 100px;
  color: rgb(var(--primary));
}
.quality-expert .expert-text h6 {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  color: rgb(66, 66, 66);
}
.quality-expert .expert-text p {
  color: rgb(var(--primary));
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
}

.steps-solution {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(252, 252, 252);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 390px;
  border-radius: 6px;
  padding: 12px;
}
.steps-solution .steps-solution-icons {
  height: 144px;
  padding: 18px;
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: rgb(246, 246, 246);
}
.steps-solution .quality-icon {
  font-size: 100px;
  color: rgb(var(--primary));
}
.steps-solution .expert-text h6 {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  color: rgb(var(--primary));
}
.steps-solution .expert-text p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: rgb(62, 62, 62);
}

.main-blogs {
  padding: 30px 100px;
}
.main-blogs .blog-img img {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-blogs .blogdetails-img img {
  height: 800px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-blogs .blog-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgb(245, 245, 245);
}
.main-blogs .blog-details .title {
  display: flex;
  justify-content: space-between;
  padding: 14px;
}
.main-blogs .blog-details .title h6 {
  color: rgb(20, 31, 57);
  font-family: "Lato", sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 29px;
  margin: 0;
  max-height: 100%;
  height: 55px;
}
.main-blogs .blog-details .title p {
  color: rgb(50, 50, 50);
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  text-align: end;
  width: 139px;
}
.main-blogs .blog-details .sub-title {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  padding: 14px;
}
.main-blogs .blog-details .sub-title p {
  color: rgb(167, 167, 167);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  overflow: hidden;
  max-width: 100%;
  height: 88px;
  width: 100%;
  background: rgb(245, 245, 245) !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.main-blogs .blog-details .sub-title a {
  text-decoration: none;
  color: rgb(50, 50, 50);
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.8px;
}

.main-footer {
  background-color: rgb(19, 19, 19);
  padding: 30px 50px;
  margin-top: 20px;
}
.main-footer .content-logo p {
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.05em;
  margin-top: 16px;
}
.main-footer .content-logo .social-icons div {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 48px;
}
.main-footer .content-logo .social-icons div svg {
  color: white;
  font-size: 42px;
  transition: all 0.3s;
}
.main-footer .content-logo .social-icons div svg:hover {
  color: #C3C3C3;
}
.main-footer .company-resources {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.main-footer .company-resources h6 {
  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
}
.main-footer .company-resources ul li {
  list-style-type: none;
}
.main-footer .company-resources ul li a {
  list-style-type: none;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0.05em;
  text-decoration: none;
}
.main-footer .product-by .contact h6 {
  color: rgb(0, 152, 219);
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  text-align: start;
}
.main-footer .product-by .contact p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #C3C3C3;
  margin: 0px;
}

.emi-locker {
  background: linear-gradient(216.85deg, #57ABFF 19.95%, #96B8F9 47.75%, #EFF6FF 87.33%);
  padding-top: 78px;
}
.emi-locker .locker-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 75px;
}
.emi-locker .locker-title h5 {
  text-align: start;
  line-height: 93px;
}
.emi-locker .locker-title .try-now {
  background-color: rgb(var(--primary));
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 32.72px;
  border: 0;
  padding: 10px 30px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.emi-locker .locker-title .play-video {
  color: rgb(var(--primary));
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
  padding-left: 8px;
  cursor: pointer;
}
.emi-locker .locker-img {
  height: 636px;
}

.we-are {
  padding: 50px 75px;
}
.we-are .we-are-title h5 {
  text-align: start;
}
.we-are .we-are-title p {
  color: rgb(62, 62, 62);
  font-family: "Lato", sans-serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 45px;
}
.we-are .we-are-title .read-more {
  background-color: rgb(var(--primary));
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 41.72px;
  padding: 4px 20px;
  border-radius: 4px;
  border: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.we-are .we-are-img {
  height: 630px;
}

.about-emi-locker {
  padding: 20px 75px;
  margin-top: 20px;
}
.about-emi-locker .about-emi {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 30px 20px;
  border-radius: 40px;
  height: 428px;
}
.about-emi-locker .about-emi h5 {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.05em;
}
.about-emi-locker .about-emi p {
  text-align: center;
  color: rgb(132, 132, 132);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.05em;
}

.locker-box {
  background-color: rgb(var(--primary));
  padding: 32px 75px;
  display: flex;
  justify-content: center;
}
.locker-box .box {
  margin-left: 40px;
  margin-right: 40px;
  text-align: center;
}
.locker-box .box .icon {
  font-size: 72px;
  background-color: rgb(244, 248, 255);
  width: 100px;
  padding: 14px 12px;
  border-radius: 48px;
  color: rgb(var(--primary));
  margin-bottom: 30px;
}
.locker-box .box h6 {
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 25px;
}
.locker-box .box p {
  color: rgb(255, 255, 255);
  font-family: Lato;
  font-size: 30px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.05em;
  margin: 0;
}

.features-locker {
  padding: 32px 75px;
}
.features-locker .features-img {
  height: 480px;
}
.features-locker .features-box {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.features-locker .features-box .check-icon {
  background-color: rgb(var(--primary));
  color: rgb(244, 248, 255);
  border-radius: 50%;
  padding: 6px;
  font-size: 36px;
}
.features-locker .features-box p {
  margin: 0;
  color: rgb(20, 31, 57);
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 47.42px;
}

.customer-busines {
  padding: 20px 75px;
}
.customer-busines .busines-box {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  height: 100%;
}
.customer-busines .busines-box h5 {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 10px;
}
.customer-busines .busines-box p {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 43.78px;
  text-align: center;
  color: rgb(151, 151, 151);
  margin: 0;
}
.customer-busines .busines-box .busines-icon {
  color: rgb(255, 255, 255);
  background-color: rgb(2, 143, 232);
  font-size: 73px;
  padding: 7px;
  width: 127px;
  border-radius: 46px;
}

.faq-accordion {
  padding: 20px 75px;
}
.faq-accordion .accordion-button {
  background-color: rgb(249, 249, 249);
}
.faq-accordion .accordion-body {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.our-newsletter {
  background-color: rgb(var(--primary));
  padding: 36px 12px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.our-newsletter h5 {
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 57.2px;
  margin-bottom: 20px;
}
.our-newsletter .input-btn {
  display: flex;
  justify-content: center;
}
.our-newsletter .input-btn input {
  background-color: rgb(248, 248, 248);
  width: 532px;
  height: 50px;
  border: 0;
  padding: 8px 20px;
}
.our-newsletter .input-btn button {
  border: 0;
  padding: 13px 16px;
  background-color: rgb(19, 19, 19);
  color: rgb(222, 222, 222);
}

.outlets-main-box {
  padding: 30px 0px;
  background-color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.outlets-main-box img {
  width: 100%;
  padding: 0px 30px;
}
.outlets-main-box .outlets-body {
  padding: 30px 0px;
  border-bottom: 1px solid rgba(207, 207, 207, 0.2);
}
.outlets-main-box .outlets-body h4 {
  color: rgb(20, 31, 57);
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 15px;
  padding-left: 30px;
}
.outlets-main-box .outlets-body p {
  color: rgb(166, 166, 166);
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0px;
  padding-left: 30px;
}
.outlets-main-box .outlets-footer {
  padding: 30px 30px 0px 30px;
}
.outlets-main-box .outlets-footer .call-information a {
  text-decoration: none;
  color: rgb(20, 31, 57);
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.outlets-main-box .outlets-footer .call-information a .phone-icon {
  background-color: rgba(0, 152, 219, 0.1);
  color: rgb(0, 152, 219);
  padding: 10px;
  font-size: 47px;
  border-radius: 10px;
  margin-right: 10px;
}
.outlets-main-box .outlets-footer .loaction-information a {
  text-decoration: none;
  color: rgb(20, 31, 57);
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.outlets-main-box .outlets-footer .loaction-information a .map-icon {
  background-color: rgba(251, 81, 81, 0.1);
  color: rgb(251, 81, 81);
  padding: 10px;
  font-size: 47px;
  border-radius: 10px;
  margin-right: 10px;
}

.our-outlates-gallery .tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}
.our-outlates-gallery .tabs .tab {
  padding: 10px 36px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(0, 152, 219, 0.1);
  color: rgb(0, 152, 219);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
}
.our-outlates-gallery .tabs .active {
  background-color: rgb(0, 152, 219);
  color: white;
}
.our-outlates-gallery .gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.our-outlates-gallery .gallery img {
  width: 500px;
  height: 350px;
  -o-object-fit: fill;
     object-fit: fill;
  margin: 10px;
  border-radius: 5px;
}
.our-outlates-gallery .gallery video {
  border-radius: 5px;
  margin: 10px;
}

.outlets-two .outlets-two-img {
  width: 93%;
}
.outlets-two .outlets-body .title-two {
  font-size: 19px;
}

.office-tour-customers h6 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
}
.office-tour-customers h6:before {
  background-color: rgb(166, 166, 166);
  content: "";
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}

.main-title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 40px;
}

.opening-current {
  padding-left: 100px;
  padding-right: 100px;
}
.opening-current .job-opening {
  background-color: rgba(152, 152, 152, 0.1);
  border: 2px solid rgba(152, 152, 152, 0.3);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 22px 12px;
  gap: 14px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  height: 288px;
}
.opening-current .job-opening h5 {
  font-family: "Lato", sans-serif;
  font-size: 41px;
  font-weight: 800;
  line-height: 61px;
  letter-spacing: 0.1em;
  color: rgb(0, 152, 219);
  background-size: 200% auto;
  animation: textclip 8s linear infinite;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-align: center;
}
.opening-current .job-opening h6 {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1em;
  color: black;
  margin: 0;
  text-align: center;
}
.opening-current .job-opening p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: var(--text-color);
  margin: 0;
}
.opening-current .job-opening a {
  border-radius: 7px;
  background-color: rgb(0, 152, 219);
  color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 10px 52px;
  border: 0;
  color: var(--white);
  font-weight: 600;
  font-size: calc(10px + 9 * (100vw - 300px) / 1300);
  margin-top: 20px;
  text-decoration: none;
}

@media only screen and (max-width: 1665px) {
  .our-partners a img {
    height: 235px;
  }
  .about-emi-locker .about-emi p {
    font-size: 14px;
  }
  .emi-locker .locker-img {
    height: 500px;
  }
  .emi-locker h5 {
    font-size: 32px !important;
    line-height: 70px !important;
  }
  .working-experience p {
    color: rgb(62, 62, 62);
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
  }
  .great-achievement .achievement-renewed .achievement h5 {
    font-size: 38px;
  }
  .great-achievement .achievement-renewed .achievement p {
    font-size: 22px;
  }
  .great-achievement .great-title {
    font-size: 39px;
  }
  .main-navbar .logo-img {
    height: 56px;
    width: 163px;
  }
  .about-company .company-img {
    height: 530px;
  }
  .happy-clients .ratings h5 {
    font-size: 60px;
    line-height: 62px;
  }
  .happy-clients .ratings h6 {
    font-size: 26px;
  }
  .happy-clients .client p {
    font-size: 22px;
    line-height: 37px;
  }
  .happy-clients .client h6 {
    font-size: 42px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 1450px) {
  .our-partners a img {
    height: 216px;
    width: 100%;
  }
  .about-emi-locker .about-emi h5 {
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.05em;
  }
  .customer-busines h5 {
    font-size: 39px;
    line-height: 54px;
  }
  .customer-busines .busines-box h5 {
    font-size: 22px;
  }
  .customer-busines .busines-box p {
    font-size: 20px;
    line-height: 26.78px;
  }
  .features-locker .features-box p {
    font-size: 19px;
  }
  .we-are .we-are-img {
    height: 523px;
  }
  .we-are .we-are-title p {
    font-size: 16px;
    line-height: 37px;
  }
  .we-are .we-are-title h5 {
    font-size: 42px;
    line-height: 54px;
  }
}
@media only screen and (max-width: 1290px) {
  .our-partners a img {
    height: 12rem;
    width: 100%;
  }
  .customer-busines {
    padding: 20px 20px;
  }
  .features-locker {
    padding: 32px 20px;
  }
  .features-locker h5 {
    font-size: 38px;
  }
  .features-locker .features-img {
    height: 361px;
  }
  .features-locker .features-box p {
    font-size: 15px;
  }
  .locker-box {
    padding: 32px 20px;
  }
  .about-emi-locker {
    padding: 20px 20px;
  }
  .about-emi-locker .about-emi {
    padding: 8px;
    margin: 15px 9px;
  }
  .about-emi-locker h5 {
    font-size: 42px;
  }
  .we-are {
    padding: 50px 30px;
  }
  .emi-locker .locker-img {
    height: 429px;
  }
  .emi-locker h5 {
    font-size: 28px !important;
    line-height: 53px !important;
  }
  .emi-locker .locker-title h6 {
    font-size: 20px;
  }
  .working-experience h6 {
    font-size: 26px;
    line-height: 47px;
    text-align: end;
  }
  .working-experience h6 p {
    font-size: 26px;
    line-height: 44px;
  }
  .happy-clients .ratings h1 {
    font-size: 80px;
  }
  .amazing-team {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .great-achievement .achievement-renewed .achievement-boxs {
    gap: 20px !important;
  }
  .help-build .better-future {
    gap: 18px;
  }
  .help-build .better-future h6 {
    font-size: 26px;
  }
  .help-build .better-future h5 {
    font-size: 36px;
    line-height: 50px;
  }
  .help-build .better-future p {
    font-size: 22px;
    margin: 0;
  }
  .help-build .better-future .learn-more {
    font-size: 22px;
    padding: 8px 27px;
  }
  .p-50 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .feedback-box {
    flex: 0 0 auto;
    width: 100%;
  }
  .embracing {
    flex-wrap: wrap;
    padding: 28px 15px;
  }
  .about-company .col-5 {
    flex: 0 0 auto;
    width: 50%;
  }
  .about-company .col-7 {
    flex: 0 0 auto;
    width: 50%;
  }
  .about-company .col-7 h5 {
    font-size: 38px;
    line-height: 48px;
  }
  .about-company .col-7 .title-About p {
    font-size: 16px;
  }
  .about-company .col-7 .customer-support .workflow h4 {
    font-size: 22px;
    line-height: 50px;
  }
  .about-company .col-7 .customer-support .workflow p {
    font-size: 16px;
  }
  .about-company .col-7 .customer-support .workflow .icon-play {
    height: 38px;
    width: 38px;
  }
  .about-company .col-7 .customer-support .workflow .icon-support {
    height: 38px;
    width: 38px;
  }
  .happy-clients {
    padding: 28px;
  }
  .happy-clients .ratings h5 {
    font-size: 62px;
  }
  .happy-clients .ratings h6 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1144px) {
  .our-partners a img {
    height: 10rem;
    width: 100%;
  }
  .main-navbar .nav-list li {
    font-size: 18px;
  }
  .customer-busines .busines-box h5 {
    font-size: 19px;
  }
  .customer-busines .busines-box p {
    font-size: 16px;
  }
  .locker-box .box p {
    font-size: 26px;
  }
  .about-emi-locker .about-emi {
    margin: 15px 0px;
  }
  .main-blogs .blogdetails-img img {
    height: 570px;
  }
  .great-achievement {
    margin-top: 20px;
  }
  .great-achievement .achievement-renewed .achievement-boxs {
    flex-wrap: wrap;
  }
  .help-build {
    padding-left: 22px;
    padding-right: 22px;
  }
  .trusted-by {
    display: flex;
    align-items: center;
  }
  .trusted-by .best-renewed img {
    height: 100% !important;
  }
  .about-company .company-img {
    height: 496px;
  }
  .renewed-experts .renewed-title {
    gap: 0px;
  }
  .renewed-experts .renewed-title h6 {
    font-size: 17px;
  }
  .renewed-experts .renewed-title h5 {
    font-size: 36px;
    line-height: 73px;
  }
  .renewed-experts .renewed-title p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1035px) {
  .customer-busines .busines-box {
    margin: 0px;
  }
  .customer-busines .col-4 {
    flex: 0 0 auto;
    width: 50%;
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .features-locker .features-box p {
    font-size: 13px;
  }
  .locker-box .box {
    margin-left: 22px;
    margin-right: 22px;
  }
  .about-emi-locker .col-4 {
    flex: 0 0 auto;
    width: 50%;
  }
  .we-are {
    display: flex;
    flex-direction: column-reverse;
  }
  .we-are .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .emi-locker h5 {
    font-size: 22px !important;
    line-height: 35px !important;
  }
  .emi-locker .locker-title h6 {
    font-size: 16px;
    line-height: 28px;
  }
  .emi-locker .locker-title .try-now {
    font-size: 18px;
    padding: 4px 25px;
  }
  .emi-locker .locker-img {
    height: 351px;
  }
  .help-build .better-future p {
    font-size: 18px;
    margin: 0;
  }
  .vision-goal {
    flex-wrap: wrap;
    padding: 28px 15px;
    margin-top: 0px !important;
  }
  .happy-clients .client {
    border-right: none;
  }
  .happy-clients .client:after {
    content: "";
    display: block;
    width: 200px;
    height: 3px;
    background: rgba(244, 244, 244, 0.1);
    margin: 0 auto;
    border-radius: 3px;
  }
  .happy-clients .col-6 {
    flex: 0 0 auto;
    width: 100%;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .happy-clients .ratings h5 {
    font-size: 47px;
    line-height: 25px;
  }
  .happy-clients .ratings h6 {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
  }
  .happy-clients .ratings .star-fill {
    font-size: 28px;
  }
  .happy-clients .client {
    gap: 0;
  }
  .happy-clients .client h6 {
    font-size: 29px;
  }
  .happy-clients .client p {
    font-size: 18px;
    line-height: 30px;
  }
  .about-company .company-img {
    height: 422px;
  }
  .about-company .customer-about {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-company .customer-about h5 {
    font-size: 30px;
    line-height: 40px;
  }
  .about-company .customer-about .title-About {
    gap: 10px;
  }
  .about-company .customer-about .title-About h6 {
    font-size: 20px;
  }
  .about-company .customer-about .title-About p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
  }
  .main-navbar .nav-list li {
    font-size: 18px;
  }
}
@media only screen and (max-width: 967px) {
  .features-locker .justify-content-start {
    justify-content: center !important;
  }
  .features-locker .col-5 {
    flex: 0 0 auto;
    width: 100%;
  }
  .features-locker .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .locker-box {
    padding: 20px 20px;
  }
  .steps-solution {
    height: 404px;
  }
  .working-experience h6 {
    font-size: 20px;
    line-height: 47px;
    text-align: end;
  }
  .working-experience p {
    color: rgb(62, 62, 62);
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
  }
  .help-build {
    display: flex;
    flex-direction: column-reverse;
  }
  .help-build .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .amazing-feedback .feedback {
    display: none;
  }
  .trusted-by {
    padding: 28px 25px;
  }
  .trusted-by .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-container .scrolled .logo-img {
    width: 40%;
  }
  .about-company .col-5 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 60px;
  }
  .about-company .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-navbar .nav-list {
    display: none;
  }
  .main-navbar .btn-get-quote {
    display: none;
  }
  .home-container .menuright {
    display: block;
  }
  .renewed-experts {
    padding: 0px 16px 30px;
  }
  .our-outlets-box .box-wrap {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 855px) {
  .featured-in .row {
    padding-left: 25px;
    padding-right: 25px;
  }
  .main-navbar {
    padding: 20px 30px;
  }
  .emi-locker .locker-img {
    height: 300px;
  }
  .emi-locker .locker-title {
    padding: 20px 28px;
  }
  .main-blogs .blogdetails-img img {
    height: 429px;
  }
  h5 {
    font-size: 40px;
    line-height: 50px;
  }
  h6 {
    font-size: 22px;
  }
  .trusted-by {
    padding: 28px 25px;
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 892px) {
  .locker-box .box p {
    font-size: 24px;
  }
  .locker-box .box {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .our-partners a img {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .locker-box {
    flex-wrap: wrap;
  }
  .locker-box .box {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .about-emi-locker h5 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .about-emi-locker .col-4 {
    flex: 0 0 auto;
    width: 100%;
  }
  .emi-locker .locker-img {
    height: 265px;
  }
  .working-experience h6 {
    font-size: 18px;
    line-height: 33px;
  }
  .working-experience p {
    font-size: 14px;
    font-weight: 500;
    line-height: 34px;
  }
  .get-touch-title {
    margin-top: 20px;
  }
  .get-boxs {
    flex: 0 0 auto;
    width: 100%;
  }
  .trusted-by {
    padding: 28px 15px;
  }
  .trusted-by .trusted-by-top .trusted-title p {
    font-size: 15px;
  }
  .trusted-by .trusted-by-top .trusted-title h4 {
    font-size: 20px;
    margin: 0;
  }
  .renewed-experts .slider-img {
    height: 231px;
    padding: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .emi-locker {
    display: flex;
    flex-direction: column-reverse;
  }
  .emi-locker .col-6 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .working-experience {
    flex-direction: column;
  }
  .main-blogs {
    padding: 30px 25px;
  }
  .main-contact .contact-title h5 {
    font-size: 60px;
    line-height: 68px;
  }
  .main-contact .contact-title h6 {
    font-size: 34px;
    line-height: 37px;
  }
  .get-touch-box {
    margin-top: 28px;
  }
  .renewed-experts .renewed-title p {
    font-size: 11px;
  }
  .renewed-experts .renewed-title h5 {
    font-size: 22px;
    line-height: 41px;
  }
  .renewed-experts .renewed-title h6 {
    font-size: 14px;
    line-height: 23.6px;
  }
}
@media only screen and (max-width: 550px) {
  .faq-accordion {
    padding: 20px 18px;
  }
  .customer-busines .col-4 {
    flex: 0 0 auto;
    width: 100%;
  }
  .features-locker .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .we-are {
    padding-top: 20px;
  }
  .we-are .we-are-img {
    height: 387px;
  }
  .gallery-img {
    padding: 20px 20px;
  }
  .happy-clients .ratings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .main-blogs .blogdetails-img img {
    height: 322px;
  }
  .help-build .better-future {
    gap: 8px;
  }
  .help-build .better-future h6 {
    font-size: 20px;
  }
  .help-build .better-future h5 {
    font-size: 29px;
    line-height: 36px;
  }
  .help-build .better-future p {
    font-size: 17px;
    margin: 0;
    line-height: 28px;
  }
  .help-build .better-future .learn-more {
    font-size: 20px;
    padding: 4px 25px;
  }
  h5 {
    font-size: 29px;
    line-height: 38px;
  }
  h6 {
    font-size: 18px;
  }
  .our-product {
    padding: 25px 0px;
  }
  .renewed-experts {
    padding: 0px 0px 30px 20px;
  }
  .renewed-experts .slider-img {
    height: 196px;
    padding: revert;
  }
}
@media only screen and (max-width: 466px) {
  .our-newsletter h5 {
    font-size: 34px;
  }
  .about-emi-locker .col-4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .about-emi-locker .about-emi {
    height: 100%;
    margin: 0;
  }
  .about-emi-locker .about-emi img {
    height: 165px;
  }
  .customer-busines h5 {
    font-size: 27px;
    line-height: 38px;
  }
  .features-locker .features-box {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .locker-box {
    flex-direction: column;
  }
  .happy-clients .ratings h1 {
    font-size: 53px;
    line-height: 42px;
  }
  .happy-clients .ratings h5 {
    font-size: 36px;
    line-height: 23px;
  }
  .happy-clients .ratings .star-fill {
    font-size: 25px;
  }
  .main-contact .get-touch-title h5 {
    font-size: 46px;
    line-height: 32px;
    margin-top: 14px;
  }
  .main-contact .get-touch-title h6 {
    font-size: 23px;
    line-height: 34px;
  }
  .great-achievement .great-title {
    font-size: 26px;
  }
  .latest-blogs .user-information .sub-title {
    font-size: 15px !important;
  }
  .latest-blogs .user-information .title {
    font-size: 20px;
    line-height: 32px;
    height: 64px;
  }
  .about-company .company-img {
    height: 382px;
  }
  .about-company .col-5 {
    margin-top: 22px;
  }
  .about-company .customer-about {
    padding-top: 15px;
  }
  .about-company .customer-about .title-About h6 {
    font-size: 18px;
    line-height: 18px;
  }
  .about-company .customer-about .title-About p {
    font-size: 11px;
  }
  .about-company .customer-about .customer-support .workflow p {
    font-size: 14px;
    margin: 0;
  }
  .about-company .customer-about .customer-support .workflow h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .about-company .customer-about .customer-support .workflow .icon-play {
    height: 30px;
    width: 30px;
    margin-top: 4px;
  }
  .about-company .customer-about .customer-support .workflow .icon-support {
    height: 30px;
    width: 30px;
    margin-top: 4px;
  }
  .main-navbar {
    padding: 20px 24px;
  }
  .main-navbar .logo-img {
    height: 56px;
    width: 212px;
  }
  .renewed-experts {
    padding: 0px 0px 25px 0px;
  }
  .renewed-experts .renewed-title h6 {
    font-size: 11px;
    line-height: 20.6px;
    margin-top: 20px;
  }
  .renewed-experts .renewed-title h5 {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 10px;
  }
  .renewed-experts .renewed-title p {
    line-height: 24px;
    font-size: 12px;
  }
  .renewed-experts .renewed-title .get-started button:nth-child(odd) {
    line-height: 22px;
    font-size: 20px;
  }
  .renewed-experts .renewed-title .get-started button:nth-child(even) {
    line-height: 30px;
    font-size: 18px;
  }
  .outlets-main-box .outlets-body h4 {
    font-size: 24px;
  }
  .outlets-main-box .outlets-body p {
    font-size: 16px;
  }
  .outlets-two .outlets-body .title-two {
    font-size: 16px;
  }
}
@media only screen and (max-width: 440px) {
  .our-outlates-gallery .gallery img {
    width: 350px;
    height: 350px;
  }
  .we-are {
    padding: 10px;
  }
  .steps-solution {
    height: 435px;
  }
  .main-footer {
    padding: 30px 22px;
  }
  .main-footer .content-logo p {
    text-align: center;
  }
  .company-resources ul {
    text-align: center;
    padding: 0;
  }
  .company-resources h6 {
    text-align: center;
  }
  .main-blogs {
    padding: 4px 12px;
  }
  .happy-clients {
    padding: 6px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .happy-clients .ratings-box {
    gap: 16px !important;
  }
  .renewed-experts .slider-img {
    height: 165px;
  }
}
@media only screen and (max-width: 400px) {
  .our-outlates-gallery .gallery img {
    width: 330px;
    height: 300px;
  }
  .features-locker .features-img {
    height: 323px;
  }
  .about-emi-locker {
    padding: 20px 12px;
  }
  .about-emi-locker .about-emi h5 {
    font-size: 32px;
    line-height: 58px;
    margin: 0;
  }
  .about-emi-locker .about-emi img {
    height: 167px;
  }
  .we-are {
    padding: 10px 10px;
  }
  .we-are .we-are-title h5 {
    font-size: 30px;
    line-height: 54px;
  }
  .we-are .we-are-title p {
    font-size: 15px;
    line-height: 27px;
  }
  .we-are .we-are-img {
    height: 324px;
  }
  .main-blogs .blogdetails-img img {
    height: 257px;
  }
  .amazing-team {
    height: 322px;
    width: 322px;
  }
  .amazing-team img {
    height: 205px;
  }
  .renewed-smartphone {
    flex-direction: column;
    align-items: center;
  }
  .happy-clients .client h6 {
    font-size: 24px;
  }
  .happy-clients .client p {
    font-size: 13px;
    line-height: 26px;
  }
  .happy-clients .ratings h5 {
    font-size: 40px;
  }
  .happy-clients .ratings h6 {
    font-size: 15px;
  }
  .happy-clients .ratings .star-fill {
    font-size: 24px;
  }
  .home-container .scrolled .logo-img {
    width: 66%;
  }
  .about-company .customer-about .customer-support .workflow p {
    font-size: 13px;
    margin: 0;
  }
  .renewed-experts .renewed-title h5 {
    font-size: 18px;
    line-height: 35px;
  }
  .renewed-experts .renewed-title p {
    font-size: 10px;
    font-weight: 800;
  }
  .renewed-experts .slider-img {
    height: 152px;
  }
  .outlets-main-box .outlets-footer div {
    flex-wrap: wrap;
    gap: 12px;
  }
  .outlets-two .outlets-footer div {
    flex-wrap: wrap;
    gap: 12px;
  }
}
@media only screen and (max-width: 360px) {
  .our-outlates-gallery .gallery img {
    width: 300px;
    height: 250px;
  }
  .we-are .we-are-img {
    height: 310px;
  }
  .we-are .we-are-title h5 {
    font-size: 26px;
    line-height: 38px;
  }
  .we-are .we-are-title p {
    font-size: 12px;
    line-height: 23px;
  }
  .steps-solution .steps-solution-icons {
    height: 106px;
    padding: 7px;
  }
  .steps-solution .expert-text p {
    margin: 0;
  }
  .happy-clients .ratings h6 {
    font-size: 13px;
  }
  .renewed-experts .renewed-title .get-started {
    flex-direction: column;
  }
  .renewed-experts .renewed-title .get-started button:nth-child(odd) {
    line-height: 22px;
    font-size: 20px;
  }
  .renewed-experts .renewed-title .get-started button:nth-child(even) {
    line-height: 22px;
    font-size: 20px;
    padding: 10px;
  }
}/*# sourceMappingURL=style.css.map */