.main-blogs{
    padding: 30px 100px;
    .blog-img {
        img{
            height: 300px;
            width: 100%;
            object-fit: cover;
        }
    }
    .blogdetails-img{
        img{
            height: 800px ;
            width: 100%;
            object-fit: cover;
        }
    }
    .blog-details{
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: rgba(245, 245, 245, 1);

        .title{
              display: flex;
              justify-content: space-between;
              padding: 14px;

            h6{
                color: rgb(20, 31, 57);
                font-family: "Lato", sans-serif;
                font-size: 19px;
                font-weight: 600;
                line-height: 29px;
                margin: 0;
                max-height: 100%;
                height: 55px;
            
            }
            p{
                color: rgb(50, 50, 50);
                font-family: "Lato", sans-serif;
                font-size: 15px;
                font-weight: 700;
                line-height: 24px;
                margin: 0;
                text-align: end;
                width: 139px;
            }
        }
        .sub-title{
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 38px;
            padding: 14px;

            p{
                color: rgb(167, 167, 167);
                font-family: "Lato", sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                overflow: hidden;
                max-width: 100%;
                height: 88px;
                width: 100%;
                background: rgb(245, 245, 245) !important;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
            a{
                text-decoration: none;
                color: rgba(50, 50, 50, 1);
                font-family: "Lato", sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 28.8px;
            }
        }
    }
}