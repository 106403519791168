.feedback-img{
    background-image: url("../images/04.png");
    background-repeat: no-repeat;  
    height: 347px;
    width: 100%;
}
.renewed-experts{
    background-image: url("../images/02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;

    .renewed-title{
        display: flex;
        flex-direction: column;
        gap: 35px;
        text-align: center;

        .get-started{
            display: flex;
            gap: 10px;
            margin-top: 20px;
            justify-content: center;
            
            button:nth-child(odd) {
                background-color: rgb(0, 152, 219);
                border: 0;
                padding: 12px 26px;
                font-family: "Lato", sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                letter-spacing: 0.05em;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgb(255, 255, 255);
                border-radius: 43px;
              }
              
              button:nth-child(even) {
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 1);
                padding: 12px 26px;
                color: rgba(255, 255, 255, 1);
                font-family: "Lato", sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                letter-spacing: 0.05em;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 43px;
              }
        }
        p{
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            color: rgba(255, 255, 255, 1);
            letter-spacing: 1px;
            margin: 0;
        }
        h5{
            font-family: "Lato", sans-serif;
            font-size: 70px;
            font-weight: 600;
            line-height: 80px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
        }
    }
    .slider-img{
        height: 100%;
        padding: 30px;
    }
}
.amazing-feedback{
    .bg_img{
        position: absolute;
    }
}
.about-company{
    .company-img{
        height: 620px;
    }
    .customer-about{
        padding-top: 75px;
        padding-bottom: 75px;
        .title-About{
            display: flex;
            flex-direction: column;
            gap: 20px;
            p{
                font-family: "Lato", sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                color: rgb(62, 62, 62);
                letter-spacing: 1px;
                margin: 0;
            }
        }
        .customer-support{
            display: flex;
            flex-direction: column;
            gap: 16px;
            .workflow{
                display: flex;
                gap: 10px;
                h4{
                    font-family: "Lato", sans-serif;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 60px;
                    letter-spacing: 0.05em;
                    color: rgba(20, 31, 57, 1);
                    margin: 0;
                }
                p{
                    font-family: "Lato", sans-serif;    
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    color:  rgba(145, 145, 145, 1);
                }
                .icon-play{
                  color: rgb(255, 255, 255);
                  background-color: rgb(var(--primary));
                  height: 50px;
                  width: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 40px;
                  padding: 0px;
                  padding-left: 6px;
                  margin-top: 6px;
                }
                .icon-support{
                    color: rgb(255, 255, 255);
                    background-color: rgb(var(--primary));
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 40px;
                    padding: 6px;
                    margin-top: 6px;
                  }
            }
        }
    }
//     .about-business{
//         position: relative;  
//         height: 100%;
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;   
//         .user-img{
//             height: 312px;
//             position: absolute;
//             top: 66px;
//             left: 119px;
//         }
//         .business-img{
//             height: 285px;
//             position: absolute;
//             top: 403px;
//             left: 375px;
//         }
//      .proton {
//         width: 280px;
//         height: 280px;    
//          background: linear-gradient(195.68deg, rgb(var(--primary),0.2) 12.89%, #FFFFFF 71.06%);
//          border-radius: 50%;
//          position: relative;
//        }
  
//   .electron {
//     width: 100%;
//     height: 100%; 
//     position: absolute;
//     animation: rotation 7s infinite linear;
//     border-radius: 50%;
//     padding: 205px;
//     border: 2px dashed  rgb(var(--primary));
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     // &::before {
//     //     content: "";
//     //     width: 20px;
//     //     height: 20px;
//     //     background-color: rgb(110,101,229);
//     //     position: absolute;
//     //     left: 20px;
//     //     top: -20px;
//     //     border-radius: 50%;
//     //     margin: 51px;
//     //   }
//   }
//   .electron-two {
//     width: 100%;
//     height: 100%; 
//     position: absolute;
//     animation: rotation 5s infinite linear;
//     border-radius: 50%;
//     padding: 205px;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     &::before {
//         content: "";
//         width: 20px;
//         height: 20px;
//         background-color: rgb(var(--primary));
//         position: absolute;
//         left: 345px;
//         top: 98px;
//         border-radius: 50%;
//         margin: 51px;
//       }
//   }
//   .electron-three {
//     width: 100%;
//     height: 100%; 
//     position: absolute;
//     animation: rotation 5s infinite linear;
//     border-radius: 50%;
//     padding: 205px;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     &::before {
//         content: "";
//         width: 20px;
//         height: 20px;
//         background-color: rgb(var(--primary));
//         position: absolute;
//         left: -57px;
//         top: 98px;
//         border-radius: 50%;
//         margin: 51px;
//       }
//   }
  
//   @keyframes rotation {
//     0% {
//       transform: translate(-50%, -50%) rotate(360deg);
//     }
//      }
//     }
}
.happy-clients{
    background-color: rgba(0, 152, 219, 1);
    padding: 68px;
    .client{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        border-right: 2px solid rgba(244, 244, 244, 0.5);

        h6{
            font-family: "Lato", sans-serif;
            font-size: 55px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: 0.05em;
            color: rgba(255, 255, 255, 1);

        }
        p{
            font-family: "Lato", sans-serif;
            font-size: 28px;
            font-weight: 400;
            line-height: 48px;
            color: rgba(255, 255, 255, 1);
        }
    }
    .ratings{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        
        h1{
            font-family: "Lato", sans-serif;
            font-size: 100px;
            font-weight: 700;
            line-height: 62px;
            color:  rgba(255, 255, 255, 1);
        }
        .star-fill{
            color: rgb(255,193,7);
            font-size: 34px;
        }
        h5{
            font-family: "Lato", sans-serif;
            font-size: 80px;
            font-weight: 700;
            line-height: 62px;
            color:  rgba(255, 255, 255, 1);
        }
        h6{
            font-family: "Lato", sans-serif;
            font-size: 32px;
            font-weight: 600;
            line-height: 48px;
            color: rgba(255, 255, 255, 1);
        }
    }
}
.view-all{
    background-color: rgb(var(--primary));
    color: rgb(255, 255, 255);
    font-family: "Lato", sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 52px;
    padding: 12px 42px;
    border: 0px;
    border-radius: 50px;
    margin-right: 60px;
}
.our-product{
    padding: 50px 70px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.trusted-by{
    padding: 28px 70px;
    .trusted-next-icon{
        background-color: rgb(var(--primary));
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 30px;
        position: absolute;
        right: 24px;
        bottom: 129px;
        z-index: 111;
        cursor: pointer;
    }
    .trusted-by-top{
        width: 100%;
        height: 370px;
        border-radius: 30px;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        .trusted-title{
            height: 40%;
            padding: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            p{
              color:  rgba(145, 145, 145, 1);
            }
            h4{
                font-family: "Lato", sans-serif;
                font-size: 25px;
                font-weight: 800;
                line-height: 22px;
                letter-spacing: 0.05em;
            }
        }
        .trusted-img{
            height: 60%;
            img{
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 26px 26px 0px 0px;
            }
        }
    }
}
.our-services{
    height: 390px !important;
}
.provide-facility{
    display: flex;
    align-items: center;
    gap: 20px;
    p{
        margin: 0;
        font-family: "Lato", sans-serif;   
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.05em;
        color: rgb(20, 31, 57);
    }
   
}
.employee{
    height: 80px;
    width: 100px;
    background: rgb(var(--primary),0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 18px;
    color: rgb(20, 31, 57);
}
.achieve-care{
    background-color: rgb(20, 31, 57);
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    position: absolute;
    right: 24px;
    bottom: 145px;
    z-index: 111;
    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    padding: 11px;
}
.renewed-smartphone{
    display: flex;
    gap: 30px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    background: rgb(255, 255, 255);
    padding: 24px;
    border-radius: 20px;
    
    .better-solutions{
        h4{
            font-family: "Lato", sans-serif;
            font-size: 22px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 1px;
            color: rgb(20, 31, 57);
            margin: 0;
        }
        p{
            color: rgba(124, 124, 124, 1);
            margin: 0;
        }
    }
}
.latest-blogs{
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    padding: 20px 24px;
    border-radius: 24px;
    max-width: 100%;
    
    .blogs-subtitle{
        border-top: 1px solid rgba(227, 227, 227, 1);
        padding-top: 10px;
        color: rgba(124, 124, 124, 1);
    }
    .user-profile{
        display: flex;
        justify-content: space-between;
        .user-img{
            height: 26px;
        }
        .right-arrow{
            width: 22px;
        }
        .read-more{
            cursor: pointer;
            text-decoration: none;
            color: rgba(57, 57, 57, 1);
            font-family: "Lato", sans-serif;   
            font-size: 18px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0.05em;
            text-align: left;

        }
    }
    .user-information{
         border-bottom: 2px solid  rgba(227, 227, 227, 0.5);
        .dec{
            background: rgb(var(--primary));
            color: rgb(255, 255, 255);
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.05em;
            height: 36px;
            width: 145px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;        
        }
        .title{
            font-family: "Lato", sans-serif;
            font-size: 26px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0.05em;         
            color:  rgba(20, 31, 57, 1); 
            overflow: hidden;
            max-width: 100%;
            height: 75px;
            width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;  
        }
        .sub-title{
            color: rgba(124, 124, 124, 1);
            font-family: "Lato", sans-serif;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.05em;
            overflow: hidden;
            max-width: 100%;
            height: 86px;
            width: 100%;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }
}
.latest-slick{
    .slick-arrow{
        display: none !important;
    }
    .box-blog{
        padding: 24px 14px;
        position: relative;
    }
}
.facility-employee{
    padding: 28px 150px;
}
.beyond-boundarie{
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-direction: column;
    border-radius: 30px;
    img{
        height: 110px;
    }
    p{
        text-align: center;
        color: rgba(132, 132, 132, 1);
    }
}
.embracing{
    padding: 28px 70px;
    .our-core-values{
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-direction: column;
        border-radius: 30px;
        img{
            height: 110px;
        }
        p{
            text-align: center;
            color: rgba(132, 132, 132, 1);
        }
    }
}

.amazing-feedback{
    .user_img{
        height: 47px;
    }
    .feedback{
        display: flex;
        flex-direction: column;
        background-color: rgb(var(--primary));
        width: 170px;
        height: 74px;
        padding: 10px 20px;
        gap: 4px;
        justify-content: center;
        position: absolute;
        right: 14px;
        border-radius: 30px 0px 0px 30px;

        h4{
            font-family: Lato;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0.05em;
            color: rgb(255, 255, 255);
            margin: 0;  
        }
        .star{
            color: rgb(253,253,253);
        }
    }
    .feedback-title{
        h4{
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0.05em;
            color: rgb(20, 31, 57);
            margin: 0;
        }
        p{
            color: rgb(164, 164, 164);
            font-family: "Lato", sans-serif;
            font-size: 17px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0.05em;
            margin: 0;
        }
    }
}
.featured-in{
    .row{
         padding-left: 75px;
         padding-right: 75px;
     }
    .featured-box{
        background-color: rgb(0 152 219 / 32%);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;


        img{
            border-radius: 10px;
        }
        a{
            padding: 0px 9px; 
            font-family: "Lato", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: 0.05em;
            color: rgb(20, 31, 57);
            text-decoration: none;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}